.vertical-scroll::-webkit-scrollbar{
    width: 8px;
    /* background-color: rgb(100 116 139); */
    /* background-color: rgba(30, 41, 59, 0.104); */
    /* border: 1px solid rgba(30, 41, 59, 0.404); */
    border-radius: 30px;
}
.vertical-scroll::-webkit-scrollbar-thumb{
    background-color: rgba(30, 41, 59, 0.100);
    border-radius: 20px;
    margin: 10px;
}

/* FONT SIZES */
.fontSize-1{
    font-size: 78px;
}
.fontSize-2{
    font-size: 64px;
}
.fontSize-3{
    font-size: 48px;
}
.fontSize-4{
    font-size: 24px;
}

/* BACKGROUND COLORS */
.bg-peachPuff{
    background-color: #FFDDA9;
}
.bg-lightPink{
    background-color: #FBF8FF;
}
.bg-purple{
    background-color: #9445f8;
}
.bg-darkPurple{
    background-color: #1a0039;
}
.bg-mediumPurple{
    background-color: #391484;
}

/* TEXT COLORS */
.t-purple{
    color: #9445f8;
}
.t-darkPurple{
    color: #330070;
}
.t-primary{
    color: #1a0039;
}
/* WIDTH */
.width-1000{
    width:1000px
}