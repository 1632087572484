.landingpage{
    background-image: url('../assets/img/landingPage.png');
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
}
.landingPage-heading{
    width: 700px;
}
.constactUs{
    background-image: url('../assets/img/contactUs.png');
    background-size: cover;
    background-repeat: no-repeat;
    /* height: 1000px; */
    width: 100%;
    /* border:2px solid red; */
}