@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: 'DM Sans',
    monospace;
}
body::-webkit-scrollbar{
  width: 10px !important;
  border-radius: 30px;
  height: 0px;
}
body::-webkit-scrollbar-thumb{
  background-color: rgba(30, 41, 59, 0.300);
  /* background-color: rgba(255, 255, 255, 0.200); */
  border-radius: 20px;
  margin: 10px;
}

/* body::-webkit-scrollbar-track{
  background-color: black;
} */